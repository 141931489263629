/* Modals */
.close > span {
  color: red;
}

/* General bootstarp */
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/* Fontawesome */
.fa-github,
.fa-question-circle {
  transition: all 0.2s ease-in-out;
  padding: 15px;
  font-size: 4rem;
}

.fa-github:hover,
.fa-question-circle:hover {
  transform: scale(1.3);
}
